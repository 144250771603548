import React from "react";
import HeaderSection from "../../Components/Homepage/HeaderSection/headerSection";
import GetQuotePage from "../GetQuote/getQuotePage";
import Capabilities from "../../Components/Homepage/Capabilities/capabilities";
import Process from "../../Components/Homepage/Process/process";
import Certification from "../../Components/Homepage/Certification/certification";
import Stats from "../../Components/Homepage/Stats/stats";
import Trustpilot from "../../Components/Homepage/Trustpilot/trustpilot";
import Calendly from "../../Components/Homepage/Calendly/calendly";
import HowSteelifyWorks from "../../Components/Homepage/HowSteelifyWorks/howSteelifyWorks";
import CompletedWorks from "../../Components/Homepage/CompletedWorks/completedWorks";
import "./homepage.css";
import NetworkMap from "../../Components/Homepage/NetworkMap/networkMap";

const Homepage = () => {
  return (
    <div className="homepage-container">
      <div className="web">
        <div className="header-and-quote">
          <div className="quote-form-section">
            <GetQuotePage />
            <Certification />
            <Trustpilot />
          </div>
          <div className="header-section">
            <HeaderSection />
            <Capabilities />
            <Process />
          </div>
        </div>
      </div>

      <div className="mobile">
        <div className="header-and-quote">
          <div className="quote-form-section">
            <Certification />
            <GetQuotePage />
            <Process />
          </div>
          <div className="header-section">
            <HeaderSection />
            <Trustpilot />
            <Capabilities />
          </div>
        </div>
        </div>
      <Stats />
      <Calendly />
      <NetworkMap />
      <HowSteelifyWorks />
      <CompletedWorks />
    </div>
  );
};

export default Homepage;

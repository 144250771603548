import React from 'react';
import './companyVideo.css';

const CompanyVideo = () => (
  <div className="about-us-how-steelify-right">
    <video
      className="about-us-steelify-video"
      controls
      poster="https://yenastorage.blob.core.windows.net/steelify/steelify_image_company_introduction.png"
    >
      <source
        src="https://yenastorage.blob.core.windows.net/steelify/steelify_video_company_introduction.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
);

export default CompanyVideo;

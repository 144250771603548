import React, { useState, useEffect } from "react";
import "./howSteelifyWorks.css";

const HowSteelifyWorks = () => {
  const [expandedParagraph, setExpandedParagraph] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const handleToggle = (paragraphNumber) => {
    setExpandedParagraph((prev) => (prev === paragraphNumber ? null : paragraphNumber));
  };

  const truncateText = (text) => {
    const words = text.split(" ");
    return words.length > 10 ? words.slice(0, 10).join(" ") : text;
  };

  const paragraph1 =
    "Steelify operates through a streamlined and transparent process to meet your manufacturing needs. It all starts with you submitting your requirements, including technical drawings, through the platform. Once your request is uploaded, Steelify reviews the details and provides a competitive quote based on your specifications. Throughout the process, you will stay updated on the status of your project, ensuring you are always informed.";

  const paragraph2 =
    "Once the quote is accepted, Steelify’s network of trusted local manufacturers begins production. Strict quality control measures are in place, with thorough inspections and precision measurements taken to ensure your parts meet the highest standards. Whether it’s welding, machining, or other fabrication services, each step is monitored closely to maintain the quality and accuracy of your order. Documentation, such as certificates and reports, is provided to guarantee that the necessary industry standards are met.";

  const paragraph3 =
    "When production is complete, your order is securely packaged and prepared for shipment. You can track your shipment in real time as it makes its way to your designated location. Steelify ensures timely delivery, and with all documentation in hand, your order arrives ready for use. This entire process reflects Steelify’s commitment to providing a reliable, transparent, and high-quality manufacturing experience.";

  return (
    <div className="how-steelify-works-container">
      <div className="how-steelify-content">
        <div className="how-steelify-left">
          <h2>How do I get started with Steelify?</h2>
          {isMobile ? (
            <p>
              {expandedParagraph === 1 ? paragraph1 : truncateText(paragraph1)}
              {paragraph1.split(" ").length > 10 && (
                <span
                  className="toggle-button"
                  onClick={() => handleToggle(1)}
                >
                  {expandedParagraph === 1 ? " Show less" : " Read more..."}
                </span>
              )}
            </p>
          ) : (
            <p>{paragraph1}</p>
          )}
        </div>
        <div className="how-steelify-right">
          <video className="steelify-video" controls>
            <source
              src="https://yenastorage.blob.core.windows.net/steelify/steelify_video_how_steelify_works.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="steelify-columns">
        <div className="steelify-column">
          <h3>What happens after I accept the quote?</h3>
          {isMobile ? (
            <p>
              {expandedParagraph === 2 ? paragraph2 : truncateText(paragraph2)}
              {paragraph2.split(" ").length > 10 && (
                <span
                  className="toggle-button"
                  onClick={() => handleToggle(2)}
                >
                  {expandedParagraph === 2 ? " Show less" : " Read more..."}
                </span>
              )}
            </p>
          ) : (
            <p>{paragraph2}</p>
          )}
        </div>
        <div className="steelify-column">
          <h3>How will I receive my order and track its progress?</h3>
          {isMobile ? (
            <p>
              {expandedParagraph === 3 ? paragraph3 : truncateText(paragraph3)}
              {paragraph3.split(" ").length > 10 && (
                <span
                  className="toggle-button"
                  onClick={() => handleToggle(3)}
                >
                  {expandedParagraph === 3 ? " Show less" : " Read more..."}
                </span>
              )}
            </p>
          ) : (
            <p>{paragraph3}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowSteelifyWorks;
